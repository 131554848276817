const endpoints = {
  // setting API
  settings: { url: "/settings", method: "GET" },
  // login and profile module
  login: { url: "/login", method: "POST" },
  forgotPassword: { url: "/forgot-password", method: "POST" },
  verifyOTP: { url: "/verification", method: "POST" },
  resendOTP: { url: "/resend-otp", method: "GET" },
  logout: { url: "/logout", method: "GET" },
  refreshToken: { url: "/refresh-token", method: "POST" },
  // api log
  apiLogList: { url: "/api-logs", method: "POST" },
  apiLogInfo: { url: "/api-log-info", method: "POST" },
  exportApiLogList: { url: "/api-log-export", method: "POST" },
  // change request list
  changeRequestList: { url: "/change-number-request", method: "POST" },
  updateChangeRequestStatus: {
    url: "/update-change-number-status",
    method: "POST",
  },
  // user list
  userList: { url: "/users", method: "POST" },
  userInfo: { url: "/user-info", method: "POST" },
  addUser: { url: "/create-user", method: "POST" },
  updateUser: { url: "/update-user-profile", method: "POST" },
  editUserHistoryList: { url: "/user-update-history", method: "POST" },
  // school list
  schoolList: { url: "/school", method: "POST" },
  rolewiseSchoolList: { url: "/fetch-school-role-wise", method: "POST" },
  // district
  districtList: { url: "/district", method: "POST" },
  // repository
  repositoryList: { url: "/leader-board", method: "POST" },
  repositoryActivityList: { url: "/school-dashboard", method: "POST" },
  fetchThemeUnlockDetails: {
    url: "/fetch-theme-unlocking-detail",
    method: "POST",
  },
  updateThemeUnlockDetails: {
    url: "/update-theme-unlocking-detail",
    method: "POST",
  },
  fetchNotificationSettingDetails: {
    url: "/fetch-notification-settings",
    method: "POST",
  },
  updateNotificationSettingDetails: {
    url: "/update-notification-settings",
    method: "POST",
  },
  exportThemeData: { url: "/theme-data-export", method: "GET" },
  exportTeacherThemeData: { url: "/theme-data-export-teacher", method: "GET" },
  // admin profile
  getProfileDetails: { url: "/profile", method: "GET" },
  updateProfileDetails: { url: "/update-profile", method: "POST" },
  // OTP master
  otpMasterList: { url: "/otp-report", method: "POST" },
};
export default endpoints;
